@import "/src/assets/sass/breakpoints";

.o-daycoval-historia {
  swiper {
    padding: 0 30px !important;
    height: 50px;
    max-width: 100%;
    background: #fff;

    > .swiper-container {
      position: static;
    }
    .swiper-slide {
      .swiper-slide-item {
        width: 100%;
      }
      a {
        font-family: "Mulish100";
        font-size: 18px;
        color: #222;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        &:hover {
          text-decoration: none;
        }
      }
      .active {
        color: #fff;
        font-family: "Mulish800";
        background-color: #0088ca;
        a {
          color: #fff;
          font-family: "Mulish800";
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      --swiper-navigation-size: auto;
      width: 16px;
      color: #0088ca;
      font-weight: bold;
    }

    > .swiper-container.swiper-container-vertical {
      overflow: visible;
      .swiper-slide {
        overflow: visible;
        height: auto;
        .swiper-slide-item {
          padding: 3px 0 4px;
        }
        .active::before {
          content: "";
          width: 24px;
          height: 24px;
          background: #0088ca;
          top: 5px;
          right: -12px;
          position: absolute;
          transform: rotate(45deg);
        }
      }
      > .swiper-button-prev,
      > .swiper-button-next {
        transform: none;
        width: 100%;
        height: 40px;
        left: 0;
        background-color: #fff;
        border-right: 18px solid transparent;
        width: 107px;
        &::after {
          transform: rotate(90deg);
        }
      }
      > .swiper-button-prev {
        margin-left: 0;
        top: 0;
      }
      > .swiper-button-next {
        margin-left: 0;
        bottom: 0;
        top: auto;
      }
    }

    > .swiper-container.swiper-container-horizontal {
      max-width: 100%;
      .swiper-slide {
        padding: 0 3px;
        display: flex;
        justify-content: center;
      }
      > .swiper-button-prev,
      > .swiper-button-next {
        margin-top: -9px;
      }
    }

    @media (min-width: 768px) {
      padding: 40px 0 !important;
      margin-right: 18px;
      height: 524px;
      overflow: visible;
    }
  }
}

.col-swipe-control {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}
.swipe-control {
  display: flex;
  position: static !important;

  .swiper-button {
    position: static;
    outline: unset;
  }
}
.swiper-button-cicle {
  border: 2px solid white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-left: 20px;
  margin-top: 0;
  &::before,
  &::after {
    font-size: 12px;
    color: white;
  }
}

.swiper-button-grey {
  border-color: #666666;
  &::before,
  &::after {
    color: #666666;
  }
}

.swiper-button-black {
  border-color: #222;
  &::before,
  &::after {
    color: #222;
  }
}

.swiper-inativo {
  transform: translate3d(0px, 0px, 0px) !important;
  justify-content: center !important;
}

@include media-breakpoint-down(sm) {
  .swiper-button-cicle {
    width: 30px;
    height: 30px;
  }
  .swiper-button-next {
    right: 22px;
  }
  .swiper-button-prev {
    left: 22px;
  }
}

@media (min-width: 1140px) {
  swiper {
    max-width: 1140px;
    margin: 0 auto;
  }
}
