.oda-chat-wrapper .btn,
.oda-chat-wrapper button {
  text-transform: none !important;
}

.DayaneBox {
  .alert {
    padding: 15px;
    &.alert-flutuante {
      width: 173px !important;
    }

    .close {
      top: 0px;
      right: 10px;
    }

    .font-16-bold {
      margin-left: 0px;
    }
  }

  .oda-chat-button {
    bottom: -3px;
  }

  @include media-breakpoint-down(xs) {
    right: -308px;
  }

  @include media-breakpoint-down(sm) {
    right: -309px !important;
    top: 56px !important;

    .oda-chat-button-icon {
      top: 39px;
      display: block;
      left: -100px;
      position: relative;
      width: 50%;
      height: 50%;
    }

    .alert {
      border-radius: 10px 10px 0 0 !important;
    }

    .closedDayane {
      width: 20px;
      height: 20px;
      margin-top: -8px;
      top: 22px !important;
      right: 20px !important;
    }
  }
}
