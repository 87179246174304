@font-face {
  font-family: 'Roboto-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-weight: bold;
  font-style: normal;
  src: url('fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Roboto-Medium.ttf') format('truetype');
}
