@import "/src/assets/sass/breakpoints";
$altura-menu: 485px;
$cor-menu-descricao-azul: #00194d;
$cor-menu-links-azul: #00194d;
$bg-section-azul: linear-gradient(to right, $cor-menu-descricao-azul 0%, $cor-menu-links-azul 50%, #fff 50%);

$cor-menu-descricao-dark: #202530;
$cor-menu-links-dark: #202530;
$bg-section-dark: linear-gradient(to right, $cor-menu-descricao-dark 0%, $cor-menu-links-dark 50%, #fff 50%);

#header {
  top: 0;
  z-index: 100;
  width: 100%;
  display: block;
}

section {
  &.menu-generico {
    height: $altura-menu;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 125px;
    left: 0;

    #menu-descricao {
      padding: 30px 32px 0 30px;

      h1 {
        font-family: "Mulish100";
        font-size: 32px;
        line-height: 1.25;
        text-align: left;
        color: #a8e3ff;
        white-space: normal;
      }

      p {
        opacity: 0.72;
        font-family: "Muli-Light";
        font-size: 16px;
        line-height: 1.43;
        text-align: left;
        color: #ffffff;
        width: 90%;
        white-space: normal;
      }

      .ponto-grande {
        width: 8px;
        height: 7px;
        background-color: #a8e3ff;
        margin: 0px 0 0 7px;
        display: inline-block;
      }
    }

    .menu-links {
      position: relative;
      height: $altura-menu;
      padding: 20px 30px 0 15px;

      a {
        font-family: "Muli-bold";
        font-size: 16px;
        text-align: left;
        color: #1b438b;
        text-decoration: none;
        margin: 2.5px 0 2.5px 10px;
        letter-spacing: 0.16px;
        padding: 3px 10px;
        width: 100%;

        &:hover,
        &.active {
          opacity: 1;
          color: #1b438b;
          background-color: #eef7fc;
        }
      }
    }
  }

  &.menu-principal {
    background: $bg-section-azul;

    #menu-descricao {
      border-radius: 0 10px 10px 0;
      background-color: $cor-menu-descricao-azul;
    }
    .menu-links {
      background-color: #fff;
    }
  }
}

@include media-breakpoint-down(md) {
  section {
    &.menu-generico {
      background: #fff !important;
      height: calc(100vh - 123px);
      top: 123px;
      overflow-y: scroll;

      .menu-links {
        position: relative;
        height: auto;
        padding: 0 !important;

        .container {
          height: 100%;
        }
        .back-menu {
          font-size: 18px;
          color: #1b438b;
          font-family: "Mulish800";
        }
        .row-menus {
          height: calc(100vh - (114px + 74px));
        }
        .col-menus {
          overflow-y: scroll;
          height: 90%;
          position: relative;
          z-index: 100000000;
          width: 100%;
          padding-bottom: 35px;
        }
        a {
          color: #1b438b;
          font-size: 16px;
          opacity: 1;
        }
      }
    }
  }
}
