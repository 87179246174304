.label-embutido {
  height: 58px;
  padding: 0px 10px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1.5px $color-primary;
  width: 100%;
  margin-bottom: 0;

  span {
    font-size: 12px;
    color: #999999;
    display: none;
  }

  input {
    background-color: white;
    padding: 0;
    border: none;
    height: 30px;
    margin: 11px 0 5px;

    &:focus {
      box-shadow: none !important;
      border-color: transparent !important;
    }

    &[type="text"] {
      font-family: "Muli-Regular";
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      color: #222222;
    }
    &:required {
      box-shadow: none;
    }
    &:invalid {
      box-shadow: 0 0 3px none;
    }
  }

  &.showSpan {
    span {
      display: block;
    }

    input {
      margin: 0px 0 5px;
    }
  }
}

.erro {
  color: #c71030 !important;
  background-color: #ffdee6 !important;
}

.sucesso{
  color: #22a226!important;
  background-color: #ecffeb!important;
}

.label-erro {
  display: block !important;
  font-family: "Muli-Regular";
  font-size: 15px;
  line-height: 1.67;
  text-align: left;
  color: #c71030;
}
