$color-primary: #0088ca;

section {
  .mat-input-element {
    caret-color: $color-primary !important;
  }

  .form-padrao {
    .mat-form-field {
      width: 100%;
      line-height: normal;

      &.mat-form-field-disabled {
        .mat-form-field-label {
          color: #999999 !important;

          &.mat-form-field-empty {
            color: #999999 !important;
          }
        }
      }

      &:not(.mat-form-field-invalid) {
        .mat-form-field-label {
          color: #999999;

          &.mat-form-field-empty {
            color: #222222;
          }
        }

        &.mat-focused {
          .mat-form-field-label {
            color: #999999;

            &.mat-form-field-empty {
              color: #999999;
            }

            .mat-form-field-required-marker{
              color: #999999;
            }
          }
        }
      }

      &.mat-form-field-invalid {
        .mat-form-field-flex {
          border-color: #f44336;
        }
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 15px;
    }

    .mat-form-field-underline{
      display: none;
    }

    .mat-placeholder-required {
      position: absolute;
      top: 2px;
      margin-left: 5px;
    }

    .mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #fff;
        padding: 1.3em .75em 0 .75em;

        .mat-form-field-infix {
          border-top: 0;
          padding: 0.25em 0 10px 0;
          padding-top: 0;
        }
      }
    }

    .mat-form-field-infix {
      background-color: transparent;
    }

    .mat-select {
      .mat-select-disabled {
        .mat-select-value {
          color: rgba(0, 0, 0, 0.38) !important;
        }
      }

      .mat-select-arrow {
        opacity: 0;
      }

      &:not(.mat-select-disabled) {
        .mat-select-arrow-wrapper {
          background-image: url("../svg/chevron-down-black.svg");
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: 93% 0%;
          padding-right: 0.4rem;
          font-family: "Muli-Bold" !important;
          text-align: left;
          color: $color-primary !important;
          transform: none;
          &:focus {
            box-shadow: none;
            border-color: transparent;
          }
        }

        .mat-select-arrow {
          opacity: 0;
        }

        .mat-select-value-text {
          font-family: "Muli-Bold" !important;
          color: #222222 !important;
        }
      }
    }

    h5 {
      font-family: "Mulish500";
      color: #001c55;
      font-size: 14px;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 0.1em;
      margin: 10px 0 20px;

      span {
        background: white;
        padding: 0 10px;
        padding-left: 0;
      }
    }

    .mat-radio-button {
      margin-left: 20px;
    }

    label {
      font-family: "Muli-Bold";

      .mat-radio-label-content {
        font-family: "Muli-Regular";
      }
    }

    .span-erro {
      color: #f44369;
      margin-top: -17px;
      font-family: "Muli-SemiBold";
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  &.simulador-cambio {
    #valor-desejado {
      .mat-select {
        width: 50% !important;
        margin-top: 0px !important;
      }

      .mat-select-arrow {
        margin: 20px 0 0 0 !important;
      }

      .mat-form-field-appearance-fill {
        .mat-select-arrow-wrapper {
          transform: translateY(10%) !important;
        }
      }
    }

    &:not(.mat-form-field-invalid) {
      .mat-form-field-label {
        color: #001c55 !important;
        font-family: "Muli-Bold" !important;
        font-size: 15px !important;

        &.mat-form-field-empty {
          color: #001c55 !important;
          font-family: "Muli-Bold" !important;
        }
      }
    }

    .mat-form-field-prefix {
      position: absolute !important;
      bottom: 1.68em !important;
      color: #999999 !important;
    }

    .mat-form-field-infix {
      -ms-flex-align: center !important;
      align-items: center !important;
      display: -ms-flexbox !important;
      display: flex !important;
      min-height: 60px !important;

      input {
        &.mat-input-element {
          padding-left: 25px;
          margin-top: 1.12em !important;
          color: $color-primary;
        }
      }

      .mat-select {
        margin-top: 1em !important;
      }
    }

    .mat-form-field-label-wrapper {
      top: 0px !important;
    }

    .mat-form-field-appearance-fill {
      .mat-form-field-flex {
        padding: 0 0.75em 0 0.75em !important;
      }

      .mat-form-field-label {
        top: 1.3em !important;
      }
    }

    .mat-select-value-text {
      font-family: "Muli-ExtraBold";
      font-size: 18px;
      font-weight: 800;
      text-align: left;
      color: $color-primary;
    }

    .mat-select-arrow-wrapper {
      background-image: url("../svg/chevron-down-primary.svg");
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 93% 0%;
      padding-right: 0.4rem;
      font-family: "Muli-Bold" !important;
      text-align: left;
      color: $color-primary !important;

      &:focus {
        box-shadow: none;
        border-color: transparent;
      }
    }

    .mat-select-arrow {
      opacity: 0;
    }
  }

  &.noticias{
    .form-padrao .mat-form-field-appearance-fill .mat-form-field-flex{
      padding: 0.7em 0.75em 0 0.75em;
    }
  }

  .form-padrao-color-2 {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: #efeded;

    }
  }
}

.mat-form-field {
  font-family: "Muli-Bold";
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.mat-form-field-infix {
  background-color: white;
  border-radius: 6px;
}

.mat-form-field-appearance-legacy {
  .mat-form-field-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    text-align: left;
    color: #999999;
    margin-left: 8px;
  }
}

.mat-select-panel:not([class*="mat-elevation-z"]) {
  overflow-x: hidden !important;
}



.politica-patrocionio{
  .form-padrao .mat-select:not(.mat-select-disabled) .mat-select-arrow-wrapper{
    background-position: 93% -5px;
  }
}
