section{
  &.detalhe-generico{
    background-color: #f6f6f6;

    .container {
      &.titulo {
        h1 {
          color: #1b438b;
          text-align: center;
        }
      }
      #link-voltar{
        @include media-breakpoint-down(xs) {
          top: 0;
        }
      }
    }

    #link-voltar {
      margin-top: 11px;
      top: 45px;
      left: 0;

      i {
        margin-right: -93px !important;
        color: #0088ca;
      }

      a {
        cursor: pointer;
        color: #0088ca;
        font-family: "Mulish500";
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
        margin-top: -3px;

        &:hover {
          color: #0088f6;
        }
      }
    }
  }
}
