.z99{
  z-index: 99!important;
}

.ocultar-scrollbar {
  overflow: hidden;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Mozila */
}

.no-border {
  border: 0px !important;
}

.no-space {
  padding: 0 !important;
  margin: 0 !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.box-circle {
  $tamanho: 50px;
  height: $tamanho;
  width: $tamanho;
  border-radius: 100%;
}

.pointer {
  cursor: pointer !important;
}

.hover {
  cursor: pointer;

  &:hover {
    transition: .2s;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

.center {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.box-content-white {
  border-radius: 8px;
  background-color: #fff;
}

.box-content-white-shadow {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(34, 34, 34, 0.05);
}

.box-img {
  position: relative;
  background-repeat: no-repeat;

  &.cover {
    width: 100%;
    background-size: cover;
  }
}

.box {
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(34, 34, 34, 0.05);
  background-color: white;

  &.highlight {
    box-shadow: 0 0px 20px 6px rgba(0, 136, 202, 0.1);
  }
}

.titulo-link-voltar {
  h1 {
    color: #1b438b;
  }

  #link-voltar {
    z-index: 99;
    margin-top: 11px;
    top: 45px;
    left: 0;

    i {
      margin-right: -93px !important;
      color: #0088ca;
    }

    a {
      cursor: pointer;
      color: #0088ca;
      font-family: "Mulish500";
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      margin-top: -3px;

      &:hover {
        color: #0088f6;
      }
    }
  }
}

.no-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.vl {
  border-left: 4px solid;
  height: 36px;
  border-radius: 3px;
}

.box-warning {
  color: #8a6d3b;
  font-size: 14px;
  font-family: "Muli-Bold";
  background-color: #fcf8e3 !important;
  padding: 20px;

  b {
    color: black !important;
  }

  *:not(.icon) {
    font-size: 14px;
    font-family: "Muli-Bold";
    color: #8a6d3b;
  }
  a {
    color: #0088ca !important;
  }
}

.box-warning-icon {
  position: relative;
  padding-left: 56px;
  .icon {
    position: absolute;
    left: 18px;
    top: 50%;
    margin-top: -12px;
  }
}

#tab-tipo-produto {
  padding: 10px;
}

.check-icon {
  border: 1px solid #009e73;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  overflow: visible;
  position: relative;
  display: inline-block;
  margin-right: 7px;

  .material-icons {
    transform: rotate(-4deg);
    position: absolute;
    font-size: 14px;
    left: 1px;
    font-weight: bold;
    top: -3px;
    color: #009e73;
  }
}

.check-circle {
  .icone {
    background-image: url("/assets/img/icons/check-circle.png");
    background-size: cover;
    position: absolute;
    left: 0;
    top: 3px;
    width: 34px;
    height: 33px;
  }
}

.check-circle-sm {
  padding-left: 20px !important;
  .icone {
    background-image: url("/assets/img/icons/check-circle-sm.png");
    width: 15px;
    height: 15px;
  }
}

.check-circle-sm-white {
  padding-left: 20px !important;
  .icone {
    background-image: url("/assets/img/icons/check-circle-sm-white.png");
    width: 15px;
    height: 15px;
  }
}

.no-selection {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.no-scrollbar {
  overflow: hidden !important;
  -ms-overflow-style: none !important; /* IE 11 */
  scrollbar-width: none !important; /* Mozila */
}

.uppercase {
  text-transform: uppercase;
}

.quebra-pagina{
  page-break-before: always;
}

/*======================== BORDER =============================*/

@media (min-width: 576px) {
  .border-sm-0 {
    border: none !important;
  }
  .border-sm-top {
    border-top: 1px solid #bebebe !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #bebebe !important;
  }
  .border-sm-right {
    border-right: 1px solid #bebebe !important;
  }
  .border-sm-left {
    border-left: 1px solid #bebebe !important;
  }
}

@media (min-width: 768px) {
  .border-md-0 {
    border: none !important;
  }
  .border-md-top {
    border-top: 1px solid #bebebe !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #bebebe !important;
  }
  .border-md-right {
    border-right: 1px solid #bebebe !important;
  }

  .border-md-left {
    border-left: 1px solid #bebebe !important;
  }
}

@media (min-width: 992px) {
  .border-lg-0 {
    border: none;
  }
  .border-lg-top {
    border-top: 1px solid #bebebe !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #bebebe !important;
  }
  .border-lg-right {
    border-right: 1px solid #bebebe !important;
  }
  .border-lg-left {
    border-left: 1px solid #bebebe !important;
  }
}

@media (min-width: 1200px) {
  .border-xl-0 {
    border: none;
  }
  .border-xl-top {
    border-top: 1px solid #bebebe !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #bebebe !important;
  }
  .border-xl-right {
    border-right: 1px solid #bebebe !important;
  }
  .border-xl-left {
    border-left: 1px solid #bebebe !important;
  }
}
