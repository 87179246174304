$size-default: 35px;

.bg-config {
  background-size: contain;
  background-repeat: no-repeat;
}

.size-default {
  width: $size-default;
  height: $size-default;
}

span {
  &.icone {
    @extend .bg-config;
    @extend .size-default;
    display: inline-block;
  }
}

.svg-circle-doc {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/circle-doc.svg);
}

.svg-24h {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/24h.svg);
}

.svg-boleto {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/boleto.svg);
}

.svg-charts-window {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/charts-window.svg);
}

.svg-contrato {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/contrato.svg);
}

.svg-user-business2 {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/business2.svg);
}

.svg-user-rounded {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/user-rounded.svg);
}

.svg-info {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/info.svg);
}

.svg-info2 {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/info2.svg);
}

.svg-search {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/search.svg);
}

.svg-atendimento-phone {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/atendimento-phone.svg);
}

.svg-whatsapp-tema {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/whatsapp-tema.svg);
}

.svg-whatsapp {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/whatsapp.svg);
}

.svg-whatsapp-white {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/whatsapp-white.svg);
}

.svg-atendimento-phone-white {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/atendimento-phone-white.svg);
}

.svg-email-white {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/email-white.svg);
}

.svg-atendimento-janela {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/atendimento-janela.svg);
}

.svg-dolar-circle {
  @extend .bg-config;
  $size: 35px;
  width: $size;
  height: $size;
  background-image: url(../svg/dolar-circle.svg);
}

.svg-shipping {
  @extend .bg-config;
  width: 40px;
  height: 30px;
  background-image: url(../svg/shipping.svg);
}

.svg-exclamation {
  @extend .bg-config;
  $size: 100px;
  width: $size;
  height: $size;
  background-image: url(../svg/exclamation.svg);
}

.svg-check {
  @extend .bg-config;
  filter: invert(33%) sepia(37%) saturate(439%) hue-rotate(79deg) brightness(158%) contrast(115%);
  $size: 100px;
  width: $size;
  height: $size;
  background-image: url(../svg/check_circle.svg);
}

.svg-confirm {
  @extend .bg-config;
  $size: 100px;
  width: $size;
  height: $size;
  background-image: url(../svg/confirm.svg);
}

.svg-error {
  @extend .bg-config;
  $size: 100px;
  width: $size;
  height: $size;
  background-image: url(../svg/error.svg);
}

.svg-marker {
  @extend .bg-config;
  $size: 25px;
  width: $size-default;
  height: $size-default;
  background-image: url(../svg/map-marker.svg);
}

.svg-user {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/user-btn.svg);

  &.primary {
    background-image: url(../svg/user-btn-primary.svg);
  }
}

.svg-business {
  @extend .bg-config;
  @extend .size-default;
  background-image: url(../svg/business.svg);

  &.primary {
    background-image: url(../svg/business-primary.svg);
  }
}


