@import "/src/assets/sass/breakpoints";

button {
  &:disabled {
    pointer-events: none;
  }

  &.fake {
    color: transparent;
    border-color: transparent;
    background-color: transparent;
    cursor: unset;
    display: none;
  }

  &.botao-flutuante {
    position: fixed;
    right: 12px;
    z-index: 10;
    top: 150px;

    @include media-breakpoint-down(md) {
      top: 110px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

a {
  &:disabled {
    pointer-events: none;
  }

  &.fake {
    color: transparent;
    border-color: transparent;
    background-color: transparent;
    cursor: unset;
    display: none;
  }

  &.botao-flutuante {
    position: fixed;
    right: 12px;
    z-index: 10;
    top: 150px;

    @include media-breakpoint-down(md) {
      top: 110px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.btn-circle {
  width: 48px;
  height: 48px;
  border: solid 2px #222222;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;

  i {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    font-size: 35px !important;
    color: black;
    margin-top: 4px;
  }

  &:hover {
    box-shadow: 1px 0px 5px 0px rgba(185, 185, 185, 0.77);
  }

  &:active {
    box-shadow: unset;
    border: solid 2px #666666;

    i {
      color: #666666;
    }
  }
}

button,
.btn {
  text-transform: uppercase;
  border-radius: 29px;

  &.btn-primary {
    border: unset;
    font-family: "Muli-Bold";
    font-size: 14px;
    color: #fff;
    padding: 0.5rem 1.5rem;
    box-shadow: 0 2px 6px 0 rgba(7, 121, 204, 0.2);
    background-color: $color-primary;
    box-shadow: 0 2px 6px 0 rgba(7, 121, 204, 0.2);
    &:hover {
      transition-delay: 3ms;
      background-color: #14abf4 !important;
    }

    &:focus {
      background-color: $color-primary;
    }

    &.dark {
      background-color: #001c55 !important;
      height: 46px;

      &:hover {
        background-color: #1b438b !important;
      }

      &:active {
        background-color: #001c55 !important;
      }
    }

    &:active {
      background-color: $color-primary !important;
    }
  }

  &.btn-primary-medium {
    border: unset;
    font-family: "Muli-Bold";
    font-size: 14px;
    color: #fff;
    padding: 14px 30px;
    box-shadow: 0 2px 6px 0 rgba(7, 121, 204, 0.2);
    background-color: $color-primary;
    box-shadow: 0 2px 6px 0 rgba(7, 121, 204, 0.2);
    &:hover {
      transition-delay: 3ms;
      background-color: #14abf4 !important;
    }

    &:focus {
      background-color: $color-primary;
    }

    &.dark {
      background-color: #001c55 !important;
      height: 46px;

      &:hover {
        background-color: #1b438b !important;
      }

      &:active {
        background-color: #001c55 !important;
      }
    }

    &:active {
      background-color: $color-primary !important;
    }
  }

  &.btn-outline-primary {
    $cor-tema-outline: #1b438b;
    border-radius: 29px;
    border: solid 1px $cor-tema-outline;
    font-family: "Muli-Bold";
    font-size: 16px;
    font-weight: bold;
    line-height: 1.67;
    text-align: center;
    color: $cor-tema-outline;
    width: auto;

    &:hover {
      background-color: $cor-tema-outline;
      transition-delay: 3ms;
      color: white;
    }

    &:focus {
      border-color: inherit !important;
    }

    &:active {
      background-color: inherit !important;
      color: inherit !important;

      span {
        filter: brightness(0.3);
      }
    }
  }

  &.btn-outline-white {
    $cor-tema-outline: #ffffff;
    color: $cor-tema-outline;
    border-color: $cor-tema-outline;
    border-radius: 29px;

    &:hover {
      color: #001c55;
      background-color: $cor-tema-outline;
    }
  }

  &.btn-secondary {
    background-color: #001c55;
    font-size: 18px;
    border: none;
    padding: 0.9rem 2rem;
  }

  &:hover {
    transition-delay: 3ms;
    opacity: 1;
    background-color: #07348e;
  }

  &:focus {
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.arrow-link {
  font-family: "Muli-Bold";
  font-size: 18px;
  //font-weight: normal;
  text-align: left;
  color: #0088ca;
  display: inline-flex;
  align-items: center;
  .material-icons {
    margin-right: 5px;
  }
}

.btn-branco-icone-coluna {
  @extend .box;
  display: block;
  width: 100%;
  position: relative;
  padding: 20px 20px 17px 75px;
  &:hover {
    background-color: #f4f4f4;
  }
  .icon {
    margin-right: 10px;
    position: absolute;
    left: 24px;
    margin-top: 4px;
    img {
      max-width: 30px;
    }
  }
  .titulo {
    font-family: "Mulish500";
    font-size: 18px;
    color: #2c466e;
  }
}

.btn-branco-icone-linha {
  @extend .btn-branco-icone-coluna;
  padding: 20px;
  border: 1px solid #ccc;
  .icon {
    display: block;
    margin-bottom: 14px;
    position: static;
  }
}
