@font-face {
  font-family: 'Mulish100';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish300';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish500';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish700';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish800';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish900';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish1000';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Mulish-Black.ttf') format('truetype');
}
