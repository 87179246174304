.mat-tab-group[mat-stretch-tabs] > .mat-tab-header .mat-tab-label {
  padding: 0;
}

.mat-tab-label-container {
  background-color: #ffffff;

  .mat-tab-label-active {
    .mat-tab-label-content {
      font-family: "Muli-SemiBold";
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: $color-primary !important;
      white-space: normal !important;
    }
  }

  .mat-tab-label {
    height: 60px !important;
    min-width: 12% !important;

    .mat-tab-label-content {
      font-family: "Muli-Bold";
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: #222222;
      white-space: normal !important;

      @media (max-width: 1199px) {
        font-size: 17px !important;
      }
    }
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  background-color: $color-primary;
}

section {
  &.saiba-mais-produto-rf,
  &.resultado-carteira {
    .mat-tab-label-container,
    .mat-tab-header {
      border-radius: 8px !important;
    }
  }

  &.grafico-lamina {
    .mat-tab-header {
      border-bottom: none;
    }
  }

  &.box-agencia {
    .mat-tab-body-content {
      overflow: hidden !important;
      -ms-overflow-style: none !important; /* IE 11 */
      scrollbar-width: none !important; /* Mozila */
    }
  }

  &.tabs-produtos-leasing {
    .mat-tab-label-container {
      flex-grow: unset !important;
    }

    .mat-tab-header {
      border-bottom: unset !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
  }

  .tabSubcategorias {
    .mat-tab-header {
      width: 100%;
      margin: 0px auto 20px auto;
      border-radius: 5px !important;

      @media (min-width: 576px) {
        max-width: 540px;
      }

      @media (min-width: 768px) {
        max-width: 720px;
        margin-top: -20px;
        margin-bottom: 35px;
      }

      @media (min-width: 992px) {
        max-width: 960px;
      }

      @media (min-width: 1200px) {
        max-width: 1140px;
      }

      .mat-tab-label-container .mat-tab-label {
        opacity: 1;

        .mat-tab-label-content {
          font-family: "Muli-Regular";
        }
      }

      .mat-tab-labels {
        justify-content: space-between;
      }
    }

    .mat-list-item {
      font-family: "Mulish500";
      font-size: 18px;
    }

    .mat-list-single-selected-option {
      color: #fff;
      font-family: "Mulish800";
      background-color: $color-primary;
    }
  }

  &.principais-atendimentos-modal {
    .mat-tab-body {
      &.mat-tab-body-active {
        width: 900px !important;
      }
    }

    .mat-tab-label-container {
      .mat-tab-label {
        background-color: white;
      }
    }
  }
}
