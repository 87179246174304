@import "/src/assets/sass/breakpoints";

$color-primary: #0088ca;

p {
  font-family: "Muli-Regular";
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #222222;

  &.subtitulo {
    color: #666666;
  }

  &.bold{
    font-weight: bold;
  }
}

h1 {
  font-family: "Mulish500";
  font-size: 2.28rem;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
  color: #222222;
}

h2 {
  font-family: "Mulish700";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #222222;
}

h3 {
  font-family: "Mulish800";
  font-size: 18px;
  color: #222222;
}

h4 {
  font-family: "Muli-Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 1.44;
  letter-spacing: 0.16px;
  text-align: left;
  color: #000000;
}

strong {
  font-family: "Muli-Bold";
  font-size: 16px;
  font-weight: bold;
}

small {
  font-family: "Muli-Light";
  font-size: 14px;
  text-align: left;
  color: #666666;
}

a {
  cursor: pointer;
  font-family: "Muli-Regular";
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

b {
  font-family: "Muli-Bold";
  color: #222222;
}

// TEXT COLORS
.text-white {
  color: #ffffff;
}

.text-black {
  color: black!important;
}

.text-primary {
  color: $color-primary !important;
}

.text-dark-blue {
  color: #21488e !important;
}

.text-warning {
  color: #8a6d3b !important;
}

.text-aviso {
  color: #ff7f00 !important;
}

.text-success {
  color: #009e73 !important;
}

.text-danger {
  color: #c71030 !important;
}

.active {
  color: #88bee1;
}

//TEXT ALIGN
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

//ICONS
.fill-icon-white {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1.5px;
}

.fill-icon-black {
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 1.5px;
}

.fill-icon-primary {
  -webkit-text-fill-color: $color-primary;
  -webkit-text-stroke-width: 1px;
}

//WORD BREAK
.word-break-word{
  word-break: break-word
}

//ROTATE
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.underline {
  text-decoration: underline;
}

.titulo-principal {
  font-family: "Mulish500";
  font-size: 32px;
  color: #000000;
  margin-bottom: 30px;
  word-break: break-word;
  small {
    font-family: "Mulish500";
    font-size: 18px;
    color: #000000;
  }
  @media (max-width: 991px) {
    font-size: 24px;
  }
}

.titulo-principal-2 {
  @extend .titulo-principal;
  font-family: "Muli-bold";
  font-size: 32px;
  small {
    font-family: "Muli-bold";
  }
}

.titulo-principal-3 {
  @extend .titulo-principal;
  font-family: "Mulish800";
  font-size: 24px;
  @media (max-width: 991px) {
    font-size: 20px;
  }
}

.titulo-principal-4 {
  @extend .titulo-principal;
  font-family: "Muli-bold";
  font-size: 24px;
  @media (max-width: 991px) {
    font-size: 20px;
  }
}

.titulo-principal-5 {
  @extend .titulo-principal;
  font-family: "Mulish800";
  font-size: 32px;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    font-size: 24px;
  }
}

.titulo-principal-6 {
  @extend .titulo-principal;
  font-family: "Mulish500";
  font-size: 24px;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    font-size: 20px;
  }
}

.titulo-principal-destaque {
  color: $color-primary;
}

.bold{
  font-weight: bold;
}


