section {
  &.form-contato {
    background-color: #f9f9f9;

    .row-container {
      h1 {
        font-family: "Mulish800";
        font-size: 24px;
        padding-bottom: 32px;
      }

      P {
        font-family: "Muli-Light";
        font-size: 16px;
      }

      .col-form {
        .form-cliente {
          button {
            border-radius: 29px;
            box-shadow: 0 2px 6px 0 rgba(7, 121, 204, 0.2);
            padding: 13px;
            color: white;
            font-family: "Muli-Bold";
          }
        }
      }

      .col-apps {
        a {
          margin: 5px 0;

          img {
            max-width: 100%;
          }
          button {
            border-radius: 8px;
            border: solid 1px #f9f9f9;
          }
        }
      }
    }

    @media (max-width: 991px) {
      img{
        width: 96%;
      }
    }

    @media (min-width: 768px) {
      .col-apps {
        margin-top: 0px;
        a {
          margin: 10px 0;
        }
      }
    }

    @media (min-width: 992px) {
      position: relative;
      z-index: 3;

      .row-container {
        background: url(../img/background/bg-seja-nosso-cliente.png) no-repeat 0px 0;
        background-position: left bottom;
      }

      .container {
        padding: 28px 0!important;
      }
    }
  }
}
