//OCULTAR LINHA VERTICAL DOS GRÁFICOS NGX-CHARTS
[hideXAxisTicks] {
  [ngx-charts-x-axis-ticks] {
    > g {
      ~ g {
        display: none;
      }
    }
  }
}
[hideYAxisTicks] {
  [ngx-charts-y-axis-ticks] {
    > g {
      ~ g {
        display: none;
      }
    }
  }
}

