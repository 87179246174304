section {
  &.banner-lg {
    p {
      width: 80% !important;
    }
  }

  .banner {
    h1 {
      font-family: "Mulish800";
      font-size: 48px;
      color: #ffffff;
      z-index: 1;
      margin-bottom: 15px;

      @media (max-width: 575.98px) {
        font-size: 32px;
      }
    }

    p {
      font-size: x-large !important;
      margin-top: 8px;
      font-size: 18px;
      line-height: 1.44;
      color: #ffffff;
      text-align: left;
    }
  }
}

.banner-gradient {
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  background-size: cover;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Mozila */

  .banner-gradient-overlay {
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.23));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.9;
  }
}

.layer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
