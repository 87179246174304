@import "/src/assets/sass/breakpoints";

section {
  &.modal-generico {
    background-color: #fefefe;
    max-width: 760px;
    position: relative;

    .container {
      padding: 60px 50px 30px 50px;

      &.sucessoPesqSatisfacao,
      &.erroPesqSatisfacao {
        padding: 0px;
      }

      h3 {
        font-family: "Mulish800";
        font-size: 24px;
        font-weight: bold;
        line-height: 1.4;
        text-align: center;
      }

      #mensagem {
        p {
          color: black;

          a {
            color: #0088ca;
            text-decoration: underline;
          }
        }
      }
    }

    #info {
      background-color: #f6f6f6;

      .container {
        padding: 15px 50px 15px 50px;

        #icone {
          width: 6%;
        }

        #descricao {
          width: 94%;
          p {
            line-height: 1.3;
          }

          a{
            text-decoration: underline;
            cursor: pointer;
            color: #0088ca;
          }
        }
      }

      @include media-breakpoint-down(xs) {
        #icone {
          width: 20%;
        }

        #descricao {
          width: 80%!important;

          p,
          a {
            font-size: 12px;
          }
        }
      }
    }

    #close {
      top: 15px;
      right: 15px;
      color: #000000;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        color: #6d6d6d;
      }

      &:active {
        color: #000000;
        transform: scale(0.87);
      }
    }

    @include media-breakpoint-down(sm) {
      .container {
        padding: 15px 25px!important;
      }
    }

    @include media-breakpoint-down(xs) {
      .container {
        padding: 15px 15px!important;

        #icone {
          width: 50px;
        }
      }

      .svg-confirm {
        width: 80px;
        height: 80px;
      }

      h3 {
        font-size: 20px!important;
      }

      p {
        font-size: 15px;
      }
    }
  }

  &.erro-generico {
    $cor: #c71030;
    max-width: 750px;

    h3 {
      color: $cor;
    }

    .btn-outline-primary {
      border: solid 1px black;
      color: black;
    }
  }

  &.sucesso-modal {
    $cor: #009e73;

    #success-icon {
      font-size: 88px;
      color: $cor;
      margin-bottom: 15px;
    }

    h3 {
      color: $cor;
    }
  }

  &.modal-container {
    @extend .box;
    padding: 40px 70px 70px;
    position: relative;
    .close {
      top: 20px;
      right: 20px;
      position: absolute;
    }

    @include media-breakpoint-down(sm) {
      padding: 33px;
    }

    @include media-breakpoint-down(xs) {
      padding: 10px 0;
    }
  }
}

.mat-dialog-container {
  #close {
    top: 20px;
    right: 20px;
    position: absolute;
  }
}

.cdk-overlay-pane {
  max-width: 95vw;
  max-height: 95vh;
}
