@mixin header-sticky {
  position: sticky;
  top: 127px;
  z-index: 99;
}

.filtro-custom {
  position: relative;
  display: block;
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(34, 34, 34, 0.02);
  background-color: #ffffff;

  select {
    background-image: url(../img/icons/chevron-down.png);
  }

  #filtro-form {
    position: relative;
    height: 73px;

    #select-cotizacao,
    .divider {
      border-right: solid 1px #bebebe;
    }
  }

  .border-r {
    border-right: solid 1px #bebebe;
  }

  .border-r-end {
    border-right: solid 1px transparent;
  }

  .float-label,
  .form-control-placeholder {
    font-size: 75%;
    position: absolute;
    top: -29px;
    padding: 32px 0 0 12px;
    z-index: 99;
  }

  input {
    border: none;
    text-align: left !important;
    margin-top: 12px;
    margin-right: 2rem;
    padding-right: 2rem;

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
    &::placeholder {
      font-family: "Muli-Light" !important;
      font-size: 12px !important;
      line-height: 1.67;
      text-align: left;
      color: black !important;
    }
    &[type="text"] {
      font-family: "Muli-Bold" !important;
      font-size: 12px !important;
      line-height: 1.67;
      text-align: left;
      color: black !important;
    }
    &:required {
      box-shadow: none;
    }
    &:invalid {
      box-shadow: 0 0 3px none;
    }
  }

  select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: 93% 22%;
    margin-top: 12px;
    margin-right: 2rem;
    padding-right: 2rem;
    border: none;

    font-family: "Muli-Bold" !important;
    font-size: 15px !important;
    line-height: 1.67;
    text-align: left;
    color: black !important;

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }
}

table {
  margin-bottom: unset !important;

  &.custom-table {
    width: 100%;
    margin-top: 25px;

    .table-header {
      padding: 20px;
      border-radius: 6px;
      background-color: #efeded;
      height: 76px;
    }
  }

  &.custom-table2 {
    width: 100%;
    margin-top: 20px;
    @include header-sticky;

    .table-header {
      padding: 20px;
      border-radius: 6px;
      background-color: #efeded;
      height: 76px;
    }
  }

  thead {
    th {
      font-family: "Muli-Regular" !important;
    }
  }

  td {
    font-family: "Muli-Regular" !important;
  }
}

#table-row {
  margin-bottom: 10px;
  height: 76px;
  box-shadow: 5px 5px 10px 0 rgba(34, 34, 34, 0.03);
  background-color: #ffffff;
  border-radius: 8px;

  &#flex-height {
    height: unset;
  }

  p {
    margin: 0;
    padding: 0;
    color: #222222;
    text-align: center;

    &#decimal {
      font-family: "Muli-Bold" !important;
      font-weight: bold !important;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 10px 0 0;
  }
}

#table-row-mob {
  strong {
    color: black;
    font-size: 14px;
  }
  p {
    margin: 0;

    @include media-breakpoint-down(xs){
      font-size: 13px;
    }
  }

  .mat-expansion-panel-header {
    height: 90px;
    border-radius: 8px;
    background-color: #ffffff;

    @media (max-width: 575px) {
      height: 85px !important;
    }
  }

  .mat-expansion-panel-header-description {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding-right: 5%;
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    border-radius: 8px !important;
    box-shadow: 5px 5px 15px 0 rgba(34, 34, 34, 0.05) !important;
    background-color: #ffffff !important;
  }
}

#filtro-form-mob {
  .container {
    background-color: #f6f6f6;
    .form-padrao {
      .mat-form-field-wrapper {
        display: flex;
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .responsive-table {
    margin: 1em 0;
    width: 100%;
    color: #333;
    border-spacing: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
  }

  .responsive-table thead {
    display: none;
  }

  .responsive-table th {
    display: none;
  }

  .responsive-table tr {
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    display: block;
    border-radius: 2px;
    margin-bottom: 0.6rem;
    padding: 0 0 0.8rem;
  }

  .responsive-table tr td {
    text-align: left;
    display: block;
    margin: -1px 1em 0 1em;
    position: relative;
    padding-left: 35%;
    border-top: 1px solid #efeded;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  .responsive-table tr td:first-child {
    padding: 10px;
    margin: 0;
    text-align: center;
    background-color: #efeded;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }

  .responsive-table tr td:before {
    content: attr(data-th);
    font-size: inherit;
    font-weight: 600;
    color: #757575;
    width: 32%;
    display: inline-block;
    position: absolute;
    left: 0.75rem;
    top: 50%;
    margin-top: -12px;
  }
}
