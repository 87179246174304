// MAT SLIDER
$color-primary: #0088ca;

.mat-slider-horizontal {
  min-width: 100% !important;
}

.mat-slider.mat-slider-horizontal {
  .mat-slider-wrapper {
    top: 18px;
    left: 0;
    right: 0;
  }

  .mat-slider-track-wrapper {
    height: 12px;
  }

  .mat-slider-track-fill {
    background-color: rgb(230, 230, 235);
  }

  .mat-slider-track-background {
    background-color: #5e6e8a;
    height: 4px;
  }

  .mat-slider-track-fill {
    height: 4px;
  }
}

.mat-accent {
  .mat-slider-thumb {
    height: 25px;
    width: 25px;
    background-color: white;
    border: solid 2px gray;
    bottom: -14px;
  }
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) {
  .mat-slider-thumb {
    background-color: white;
  }
}

// MAT PROGRESS BAR
section {
  &.validar-token-sms-modal {
    .mat-progress-bar-background {
      fill: $color-primary;
    }

    .mat-progress-bar {
      .mat-progress-bar-buffer {
        background: #b6ddf0;
      }

      .mat-progress-bar-fill {
        &::after {
          background: $color-primary;
        }
      }
    }
  }
}

.mat-progress-bar {
  padding-bottom: 5px;
  margin-bottom: 16px;
  border-radius: 4px;

  .mat-progress-bar-fill::after {
    background: #a8e3ff;
  }

  .mat-progress-bar-buffer {
    background: #445f8f;
  }
}

//MAT SPINNER
section {
  &.loading {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: $color-primary !important;
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: white !important;
}

//MAT DIVIDER
section {
  &.simulador-investimentos-partial {
    .mat-divider {
      border-top-color: #325697;
      position: absolute;
      left: 0;
      width: 100%;
      opacity: 0.5;

      &.linha-branca {
        margin-top: 17px;
        width: 18%;
        border-color: #ffffff !important;
      }
    }
  }

  &.box-conteudo-links {
    .mat-divider {
      opacity: 0.4 !important;
      width: 20%;
    }
  }

  &.atendimento-mais-procurados {
    .mat-divider {
      border-color: #f6f6f6;
      width: 100%;
    }
  }

  &.principais-atendimentos-modal {
    .mat-divider {
      border-color: #222222;
      width: 100%;
      border-top-width: 2px;
      opacity: 0.1 !important;
    }
  }

  &.banner-md,
  &.banner-lg {
    .mat-divider {
      width: 100px !important;
      @media(max-width: 991.98px){
        &:not(.banner-lg){
          border-color: #001c55!important;
        }
      }
    }
  }
}

.mat-divider {
  border-top-width: 1px;
  border-top-color: white;
  position: absolute;
  width: 10%;
  opacity: 1 !important;
}

//MAT LIST
.mat-list-base {
  padding-top: 0px !important;
  margin-top: -5px !important;

  .mat-subheader {
    &:first-child {
      margin: 10px 0 -20px;
    }
  }
}

//MAT SORT
section {
  &.lista-fundos {
    .mat-sort-header-content {
      text-align: left !important;
    }
  }
}

.mat-sort-header-container {
  .mat-sort-header-content {
    font-family: "Muli-Bold";
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    text-align: center;
    color: #222222;
  }
}

//MAT CHECKBOX
section {
  &.agencias-correspondentes {
    .mat-checkbox {
      text-align: center;
      width: 16.66%;
    }

    .mat-checkbox-layout {
      margin-bottom: 0;
    }
  }
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: $color-primary !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-family: "Muli-Regular" !important;
}

section {
  &.segunda-via-boleto-termo {
    .mat-checkbox-label {
      font-size: 18px !important;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: white !important;
}

.mat-pseudo-checkbox-checked::after {
  color: #1b438b !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white !important;
}

.mat-tab-label.mat-tab-label-active {
  color: $color-primary;
  font-weight: bold;
}

// MAT RADIO
.mat-radio-button {
  &.mat-accent {
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $color-primary !important;
      }
    }

    .mat-radio-inner-circle {
      background-color: $color-primary !important;
    }

    .mat-radio-ripple {
      .mat-ripple-element {
        background-color: $color-primary !important;
      }
    }
  }
}

section {
  .box-agencia {
    .mat-radio-label-content {
      font-family: "Muli-Regular";
      font-size: 13px;
      text-align: left;
      color: #222222;
      white-space:normal
    }
  }
}

//MAT DIALOG
.mat-dialog-container {
  padding: 0px !important;
  border-radius: 8px !important;
  box-shadow: 0 10px 20px 0 rgba(34, 34, 34, 0.13) !important;
}

//MAT CARD
section {
  &.ouvidoria-e-voce {
    .mat-card {
      min-height: 375px;

      @media (max-width: 991px) {
        min-height: 330px;
      }
    }
  }
}
.mat-card-image {
  max-height: 300px !important;
}

//MAT LIST
section {
  &.lista-links-documentos {
    .mat-list-text {
      font-family: "Muli-SemiBold" !important;
      font-size: 16px !important;
      color: black !important;
      flex-direction: unset !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }

    .mat-selection-list {
      .mat-list-option {
        box-shadow: 0 5px 20px 0 rgba(34, 34, 34, 0.03) !important;
        border-radius: 10px !important;
      }
    }

    .mat-list-base {
      .mat-list-item,
      .mat-list-option {
        background-color: white;
        height: 60px !important;

        &:hover {
          background-color: #f8f8f8;
        }

        @media (max-width: 1199px) {
          height: 100px !important;
        }
      }
    }
  }
}

.mat-button-wrapper {
  color: black!important;
}

//MAT DATE PICKER
.mat-calendar-body-selected {
  background-color: $color-primary !important;
}

section {
  .mat-datepicker-toggle-active {
    color: $color-primary;
  }

  .mat-form-field {
    .mat-form-field-label {
      font-family: "Muli-Bold";
      color: black;
    }

    &.mat-focused {
      .mat-form-field-label {
        color: #666666;
      }
    }
  }

  .mat-form-field-underline {
    width: unset;
  }

  button:focus {
    outline: unset;
  }

  &.cotacao-dolar {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 8px;
      box-shadow: 5px 5px 10px 0 rgba(34, 34, 34, 0.03);
      background-color: #ffffff;
    }

    .mat-datepicker-toggle-default-icon {
      color: black;
    }
  }
}


