@import "material-style";
@import "font-style";
@import "buttons";
@import "backgrounds";
@import "charts-style";
@import "classes-helper";
@import "input-style";
@import "menus";
@import "grid";
@import "swiper";
@import "banners";
@import "tables";
@import "sizing";
@import "modais";
@import "componentes-contato";
@import "svg-classes";
@import "forms";
@import "mat-tab-style";
@import "dayane";
@import "breakpoints";
@import "detalhe-generico";

$color-primary: #0088ca;

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  margin: 0;
  padding: 0;
}

.mensagem-print-pdf {
  display: none;
}

.overflow-menu-fix {
  @include media-breakpoint-down(md) {
    height: 100%;
    overflow: hidden;
  }
}

.sobre-nos,
.conteudo-tab {
  .title-container {
    font-size: 32px;
    color: #000000;
    margin-bottom: 30px;
    small {
      font-family: "Mulish500";
      font-size: 18px;
      color: #000000;
    }
  }
  .title-destaque {
    color: $color-primary;
  }
  .row-container {
    padding: 25px 0 35px;
    &:nth-child(even) {
      background-color: #ffffff;
    }
    &.bg-light-gray {
      background-color: #f6f6f6;
    }
  }

  .bar-chart {
    text {
      fill: #666;
      font-family: "Muli-Bold";
      font-size: 18px !important;
    }

    .textDataLabel {
      transform: translateY(-7px);
      text-anchor: middle;
      // alignment-baseline: baseline;
    }
  }
}
 /* Move reCAPTCHA v3 badge to the left */

 .grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}
.form-control:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
  border-color: none !important;
  box-shadow: none !important;
}

@media screen {
  #printable {
    display: none;
  }
}

@media print {
  .oda-chat-button-icon {
    display: none;
  }

  .DayaneBox {
    display: none;
  }

  .titulo {
    display: none;
  }

  .detalhe-rodape {
    display: none;
  }

  .printFont10px {
    font-size: 13px !important;
  }

  .printFont9px {
    font-size: 12px !important;
  }
}

hr {
  width: 100%;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.is-h1 {
  font-family: Mulish500;
  font-size: 2.28rem;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
}

.is-saiba-mais-header {
  font-family: Mulish500;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #2c466e;
}



#rybena-sidebar .options button,
#rybena-sidebar .options button .ryb-label {
  background: #001163;
}
.custom-rybena {
  z-index: 10;
  left: -80px;
  top: 37%;
  margin: auto;
  height: 210px;
}
.custom-rybena__box {
  margin: 0;
  position: absolute;
  left: -158px;
  width: auto;
  width: 290px;
  transition: 0.3s ease-in-out;
}
.custom-rybena__box__bt {
  position: relative;
  display: block;
  margin: 0 0 5px -36px;
  background: #001163;
  float: right;
  clear: both;
  cursor: pointer;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  border-radius: 0 7px 7px 0;
  padding: 6px 0 3px;
}
.custom-rybena__box__bt:hover {
  margin: 0 -56px 5px 0;
  transition: 0.3s ease-in-out;
  background-color: #2371a1;
}
.custom-rybena__box__bt > div {
  display: table-cell;
  vertical-align: middle;
}
.custom-rybena__box__bt > div:first-child {
  width: 66px;
  text-align: right;
  padding-right: 10px;
}
.custom-rybena__box__bt > div:last-child {
  width: 45px;
  height: 45px;
}
.custom-rybena__box__bt.btn-rybena-vision {
  margin: 0 0 5px -127px;
}
.custom-rybena__box__bt.btn-rybena-vision:hover {
  margin: 0 -127px 5px 0;
  transition: 0.3s ease-in-out;
}
.custom-rybena__box__bt.btn-rybena-vision > div:first-child {
  width: 200px;
}
.custom-rybena__box__bt span {
  display: block;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.2;
  background-color: transparent !important;
}
.custom-rybena__btn {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 36px;
  position: absolute;
  left: 80px;
  top: -41px;
  background-color: #2371a1;
  cursor: pointer;
  border-radius: 0 7px 7px 0;
}
.custom-rybena__btn::after {
  content: "";
  display: block;
  position: absolute;
  width: 14px;
  height: 16px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
  background-size: contain;
  background-repeat: no-repeat;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}
.custom-rybena.menu-hidden .custom-rybena__box {
  margin: 0 0 0 -120px;
}
.custom-rybena.menu-hidden .custom-rybena__btn::after {
  content: "";
  transform: rotate(0);
  transform-origin: center center;
}

@media screen and (max-width: 460px) {
  .custom-rybena {
    right: -80px;
    left: initial;
  }
  .custom-rybena__box {
    right: 70px;
    left: initial;
    width: 60px;
  }
  .custom-rybena__box__bt {
    border-radius: 7px 0 0 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    width: 60px;
    padding: 5px 0;
  }
  .custom-rybena__box__bt:hover {
    margin: 0 0 5px -94px;
  }
  .custom-rybena__box__bt > div:first-child {
    display: none;
  }
  .custom-rybena__box__bt > div:last-child {
    width: 45px;
    height: 45px;
    padding-left: 10px;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-rybena__btn {
    right: 80px;
    left: initial;
    border-radius: 7px 0 0 7px;
  }
  .custom-rybena__btn::after {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 16px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    border-left: 0;
    left: 10px;
    right: initial;
    transform: rotate(-180deg);
  }
  .custom-rybena.menu-hidden .custom-rybena__box {
    margin: 0 -60px 0 0;
    transition: 0.3s ease-in-out;
  }
  .custom-rybena.menu-hidden .custom-rybena__btn::after {
    content: "";
    transform: rotate(0);
    transform-origin: center center;
  }
}
.custom-rybena,
.fixed {
  position: fixed;
}
