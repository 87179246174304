.w0{
  width: 0%!important;
}

.w5 {
  width: 5% !important;
}

.w10 {
  width: 10% !important;
}

.w12 {
  width: 12% !important;
}

.w13 {
  width: 13% !important;
}

.w14 {
  width: 14% !important;
}

.w15 {
  width: 15% !important;
}

.w16 {
  width: 16% !important;
}

.w17{
  width: 17%!important;
}

.w18 {
  width: 18% !important;
}

.w19 {
  width: 19% !important;
}

.w20 {
  width: 20% !important;
}

.w21 {
  width: 21% !important;
}

.w22 {
  width: 22% !important;
}

.w23 {
  width: 23% !important;
}

.w24 {
  width: 24% !important;
}

.w25 {
  width: 25% !important;
}

.w26 {
  width: 26% !important;
}

.w27 {
  width: 27% !important;
}

.w28 {
  width: 28% !important;
}
.w29 {
  width: 29% !important;
}

.w30 {
  width: 30% !important;
}

.w35 {
  width: 35% !important;
}

.w40{
  width: 40%!important;
}

.w50{
  width: 50%!important;
}

.w60{
  width: 60%!important;
}

.w70{
  width: 70%!important;
}

.w73{
  width: 73%!important;
}

.w80{
  width: 80%!important;
}

.w90{
  width: 90%!important;
}

.w100{
  width: 100%!important;
}
