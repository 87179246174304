.r4 {
  width: 23%;
  margin: 0 10px 0 10px;
}

.r6 {
  width: 16.66%;
  justify-content: center !important;
  display: flex !important;
}

.r8 {
  text-align-last: center;
  text-align: -webkit-center;
  width: 12.5%;
}

.container-right {
  @media (min-width: 768px) {
    margin-right: 0;
    margin-left: calc(50vw - 358px);
  }

  @media (min-width: 992px) {
    margin-right: 0;
    margin-left: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    padding-right: 15px;
    margin-right: 0;
    margin-left: calc(50vw - 565px);
  }
}

.container-left {
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: calc(50vw - 375px);
  }

  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    padding-left: 15px;
    margin-left: 0;
    margin-right: calc(50vw - 565px);
  }
}
