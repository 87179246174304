@font-face {
  font-family: 'Poppins-Light';
  font-weight: lighter;
  font-style: normal;
  src: url('fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-weight: bold;
  font-style: normal;
  src: url('fonts/Poppins-Bold.ttf') format('truetype');
}
