.bg-tema-dark{
  background-image: linear-gradient(to right, #11141a 25%, #242a36);;
}

.bg-grey {
  background-color: #f9f9f9 !important;
}

.bg-dark-grey {
  background-color: #efeded !important;
}

.bg-blue {
  background-color: #1b438b !important;
}

.bg-primary {
  background-color: $color-primary !important;
}

.bg-blur {
  $size-blur: 15px;
  -webkit-filter: blur($size-blur);
  -moz-filter: blur($size-blur);
  -o-filter: blur($size-blur);
  -ms-filter: blur($size-blur);
  filter: blur($size-blur);
}

.bg-quadrados {
  background-image: url(../img/background/bg-blue-squares.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-masks {
  background-image: url(../img/background/bg-masks.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-linearg {
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2721463585434174) 100%);
}

.bg-radialg {
  background: radial-gradient(circle, rgba(0,0,0,0.29735644257703087) 0%, rgba(0,0,0,0.40379901960784315) 100%);
}
